import React from "react";
import { SocialIcon } from "react-social-icons";
import { motion } from "framer-motion";

function Header({ socialInfo }) {
  const container = {
    initial: {
      x: -500,
      scale: 0.5,
      opacity: 0,
    },
    animate: {
      x: 0,
      scale: 1,
      opacity: 1,
    },
  };

  const renderSocialCards = (socialInfo) => {
    return (
      <div className="flex items-center">
        {socialInfo?.map((e, i) => {
          return (
            <motion.div
              key={i}
              variants={container}
              initial="initial"
              animate="animate"
              transition={{
                duration: 1.5,
                delay: 0.5 / (i + 1),
              }}
              className=""
            >
              <SocialIcon
                className=""
                url={e.url}
                fgColor="grey"
                bgColor="transparent"
              />
            </motion.div>
          );
        })}
      </div>
    );
  };

  return (
    <header className="sticky top-0 p-5 flex items-start xl:items-center  justify-between max-w-7xl mx-auto z-20">
      {renderSocialCards(socialInfo)}

      {socialInfo && (
        <motion.div
          initial={{
            x: 500,
            scale: 0.5,
            opacity: 0,
          }}
          animate={{
            x: 0,
            scale: 1,
            opacity: 1,
          }}
          transition={{
            duration: 1.5,
          }}
          className="flex items-center text-gray-300 cursor-pointer"
        >
          <a href="#contact">
            <SocialIcon
              fgColor="grey"
              bgColor="transparent"
              className=" cursor-pointer"
              network="email"
            />
            <p className="uppercase hidden md:inline-flex text-sm text-gray-400">
              Get In Touch
            </p>
          </a>
        </motion.div>
      )}
    </header>
  );
}

export default Header;

import React from 'react'
import { motion } from 'framer-motion'

function About({ pageInfo }) {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1.5 }}
            className='h-screen flex flex-col relative text-center md:text-left md:flex-row max-w-7xl mx-auto px-10 justify-evenly items-center '>
            <h3 className='absolute top-24 tracking-[20px] uppercase text-gray-500 text-2xl'>About</h3>
            <motion.img
                initial={{
                    x: -200,
                    opacity: 0
                }}
                whileInView={{
                    opacity: 1,
                    x: 0,
                }}

                transition={{
                    duration: 1.2
                }}

                className='-mb-20 mt-12 md:mb-0 flex-shrink-0 w-56 h-56 rounded-full object-cover md:rounded-lg md:w-64 md:h-96
                xl:h-[425px] xl:w-[375px]'
                src={pageInfo?.profileImageUrl || 'images/profileimage.jpg'} />

            <div className='space-y-4 px-0 md:px-10 md:flex md:flex-col md:justify-between md:items-center '>
                <h4 className='text-4xl font-semibold'>Here is a <span className='underline decoration-[#F7AB0A]/70'>little</span> background</h4>
                <p className='text-base'>{pageInfo?.backgroundInformation || 'A self-motivated Individual who is willing to learn new technologies and best practices, Hardworking and passionate job seeker with strong organizational skills, eager to secure Front End Developer position. Skilled in React JS and other Front End Technologies. Ready to help team achieve company goals.'}</p>
            </div>
        </motion.div>
    )
}

export default About
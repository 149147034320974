import React from "react";
import { motion } from "framer-motion";
import SkillImage from "./SkillImage";

function ExperienceCard({ data }) {
  return (
    <article
      key={data?._id}
      className="flex flex-col items-center rounded-lg space-y-4 flex-shrink-0 w-[290px] sm:w-[500px] md:w-[600px] xl:w-[900px] snap-center p-5 bg-[#292929]/60 opacity-80 hover:opacity-100 hover:bg-[#292929] duration-300 transition-opacity overflow-hidden"
    >
      <motion.img
        initial={{ y: -100, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{ duration: 1.2 }}
        viewport={{ once: true }}
        className="w-28 h-28 rounded-full xl:w-[135px] xl:h-[135px] object-cover object-center "
        src={data?.companyImageUrl}
        alt="Company Image"
      />

      <div className="px-0 md:px-10">
        <h4 className="text-2xl sm:text-4xl font-light">{data?.jobTitle}</h4>
        <p className="font-bold text-xl sm:text-2xl mt-1">{data?.company}</p>
        <div className="flex space-x-2 my-2">
          {data.technologies?.map((e) => (
            <SkillImage key={e.id} e={e} />
          ))}
        </div>

        <p className="uppercase py-2 text-gray-300">{`${data?.dateStarted} - ${
          data?.isCurrentlyWorkingHere ? "Presently Working" : data?.dateEnded
        }`}</p>
        <ul className="list-disc space-y-1 ml-5 text-md 2xl:h-44 3xl:h-72 ">
          {data.points?.map((e) => {
            return (
              <li className=" text-xs sm:text-sm" key={e._key}>
                {e.title}
              </li>
            );
          })}
        </ul>
      </div>
    </article>
  );
}

export default ExperienceCard;

import React from "react";
import { motion } from "framer-motion";
import ExperienceCard from "./ExperienceCard";

function WorkExperience({ experience }) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1.5 }}
      className="h-screen flex flex-col relative overflow-hidden text-left  md:flex-row max-w-full mx-auto px-10 justify-center items-center md:items-end  3xl:items-center  "
    >
      <h3 className="absolute top-20 md:top-24 tracking-[20px] uppercase text-gray-500 text-2xl">
        Experience
      </h3>

      <div className="flex w-full overflow-x-scroll snap-x snap-mandatory space-x-5 p-10 mt-5 sm:mt-0 scrollbar scrollbar-track-gray-400/20 scrollbar-thumb-[#F7AB0A]/80 ">
        {/* Experience cards */}
        {experience?.map((e, i) => {
          return <ExperienceCard key={i} data={e} />;
        })}
      </div>
    </motion.div>
  );
}

export default WorkExperience;

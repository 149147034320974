import React from 'react'
import { motion } from 'framer-motion'

function BackgroundCircles() {
    return (
        <motion.div
            initial={{
                opacity: 0
            }}
            animate={{
                scale: [1, 3, 2.8, 1],
                //scale: [1, 2, 2, 3, 1],
                opacity: [0.1, 0.2, 0.4, 0.8, 0.1, 1],
                borderRadius: ['20%', '20%', '50%', '80%', '20%']
            }}
            transition={{
                duration: 2.5,
                delay: 1
            }}
            className='relative flex items-center justify-center '>
            <div className='absolute border border-[#333333] rounded-full h-[50px] w-[50px] mt-52 animate-newping' ></div>
            <div className='absolute border border-[#333333] rounded-full h-[300px] w-[300px] mt-52 ' ></div>
            <div className='absolute border border-[#333333] rounded-full h-[500px] w-[500px] mt-52 ' ></div>
            <div className='absolute border border-[#F7AB0A]/40 rounded-full h-[650px] w-[650px] mt-52 animate-newpulse'></div>
            <div className='absolute border border-[#333333] rounded-full h-[800px] w-[800px] mt-52'></div>
        </motion.div>
    )
}

export default BackgroundCircles
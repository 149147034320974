import React from "react";
import { Cursor, useTypewriter } from "react-simple-typewriter";
import BackgroundCircles from "./BackgroundCircles";

function Hero({ pageInfo }) {
  const [text, count] = useTypewriter({
    words: [`Hi I am Ajnas A`, "Front-End Developer", "<React.JS/>"],
    loop: true,
    delaySpeed: 2000,
  });
  return (
    <div className="flex flex-col space-y-8 items-center justify-center text-center h-screen overflow-hidden">
      <BackgroundCircles />
      <img
        className="relative h-32 w-32 bg-red-200 rounded-full mx-auto object-cover"
        src={pageInfo?.heroImageUrl || "images/profileimage.jpg"}
        alt=""
      />
      <div className="z-20">
        <h2 className="uppercase text-sm text-gray-500 tracking-[9px] sm:tracking-[15px] pb-2">
          {pageInfo?.role || "Software Engineer"}
        </h2>

        <h1 className="text-4xl lg:text-6xl font-semibold text-gray-200 px-10 mb-2">
          <span className="">{text}</span> <Cursor cursorColor="#F7AB0A" />
        </h1>
        <div className="flex items-center justify-center sm:space-x-5">
          <a href="#about" className="herobutton">
            About
          </a>
          <a href="#experience" className="herobutton">
            Experience
          </a>
          <a href="#skills" className="herobutton">
            Skills
          </a>
          <a href="#projects" className="herobutton">
            Projects
          </a>
        </div>
      </div>
    </div>
  );
}

export default Hero;

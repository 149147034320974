import React from 'react'
import { motion } from 'framer-motion'

function Skill({ imgUrl, title }) {
    return (
        <div className='group relative flex cursor-pointer'>
            <motion.img
                initial={{ opacity: 0 }}
                whileInView={{ opacity: [0.5, 1] }}
                transition={{ duration: 1 }}
                src={imgUrl} alt=""
                className='rounded-full border border-gray-500 h-20 w-20  md:h-28 xl:h-32 md:w-28 xl:w-32 object-cover p-1 filter  hover:opacity-50 hover:grayscale duration-300 ease-in-out transition' />

            <div className='w-full h-full absolute bg-gray-200/90 rounded-full opacity-0 hover:opacity-75 flex items-center justify-center transition duration-300'>

                <div className=' text-black font-bold' >
                    {title}
                </div>

            </div>
        </div>
    )
}

export default Skill
import React from "react";
import { motion } from "framer-motion";
import SkillImage from "./SkillImage";
import LinkToBuild from "./LinkToBuild";

function Projects({ projectsInfo }) {
  return (
    <motion.div
      initial={{ y: -100, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      transition={{ duration: 1.2 }}
      viewport={{ once: true }}
      className="relative h-screen flex overflow-hidden flex-col text-left md:text-left  max-w-full sm:justify-center justify-start md:justify-end mx-auto items-center z-0 p-1"
    >
      <h3 className="absolute top-24 tracking-[20px] uppercase text-gray-500 text-2xl">
        Projects
      </h3>

      <div className="relative w-full flex overflow-x-scroll overflow-y-hidden snap-x snap-mandatory z-20 scrollbar-thin scrollbar-track-gray-400/20 scrollbar-thumb-[#F7AB0A]/80 h-[660px] sm:h-auto">
        {projectsInfo?.map((e, i) => (
          <div
            key={e._id}
            className="w-screen flex-shrink-0 snap-center flex flex-col space-y-5 lg:space-y-2 xl:space-y-5 items-center justify-center mt-7 sm:m-0 p-12 sm:justify-end md:justify-end lg:justify-start 2xl:justify-start 3xl:justify-center md:p-0  lg:p-44 h-screen"
          >
            <motion.img
              className="h-[180px] w-[320px]  md:h-[200px] md:w-[350px]  lg:h-[200px] lg:w-[350px] 2xl:w-[400px] 2xl:h-[250px] object-fill"
              initial={{ opacity: 0.5 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
              src={e.image}
              alt=""
            />

            <div className=" space-y-3 lg:space-y-2 xl:space-y-5 px-0 md:px-10 max-w-6xl">
              <h4 className=" text-2xl sm:text-4xl font-semibold text-center">
                {e.title}
              </h4>
              <div className="flex space-x-2 my-2 justify-center">
                {e.technologies?.map((e) => (
                  <SkillImage key={e.id} e={e} />
                ))}
              </div>

              <LinkToBuild e={e} />
              <p className=" text-sm md:text-lg text-center md:text-left overflow-y-auto h-44 sm:h-24 md:h-44 lg:h-28 scrollbar-thin scrollbar-thumb-[#F7AB0A]/80 px-2 ">
                {e.summary}
              </p>
            </div>
          </div>
        ))}
      </div>

      <div className="w-full absolute top-[30%] bg-[#F7AB0A]/10 left-0 h-[350px] md:h-[400px] -skew-y-12 md:-skew-y-6 " />
    </motion.div>
  );
}

export default Projects;

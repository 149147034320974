import About from "./components/About";
import Contact from "./components/Contact";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Projects from "./components/Projects";
import Skills from "./components/Skills";
import WorkExperience from "./components/WorkExperience";
import { useEffect, useState } from "react";
import Axios from "./axios";
import { useInView } from "react-intersection-observer";
import { Toaster } from "react-hot-toast";

function App() {
  const [data, setData] = useState({});
  const { ref, inView } = useInView({
    threshold: 0.5,
  });
  const api = async () => {
    const apiData = await Axios.get("/v2/getData");
    setData(apiData.data);
  };

  useEffect(() => {
    api();
  }, []);

  return (
    <div className="App">
      <div className="bg-[rgb(36,36,36)] h-screen text-white snap-y snap-mandatory overflow-y-scroll overflow-x-hidden z-0  scrollbar-track-gray-400/20 scrollbar-thumb-[#F7AB0A]/80 scrollbar-thin relative">
        <Header socialInfo={data?.socials} />

        <section ref={ref} id="hero" className="snap-start ">
          <Hero pageInfo={data?.pageInfo && data.pageInfo[0]} />
        </section>

        <section id="about" className="snap-center">
          <About pageInfo={data?.pageInfo && data.pageInfo[0]} />
        </section>

        <section id="experience" className=" snap-center">
          <WorkExperience experience={data?.experience} />
        </section>

        <section id="skills" className="snap-start">
          <Skills skillInfo={data?.skill} />
        </section>

        <section id="projects" className="snap-start">
          <Projects projectsInfo={data?.projects} />
        </section>

        <section id="contact" className="snap-start">
          <Contact pageInfo={data?.pageInfo && data.pageInfo[0].email} />
        </section>

        <footer className="fixed bottom-5 sm:bottom-5 w-full cursor-pointer">
          <div className="flex items-center justify-center">
            <a
              id="#hero"
              href="#hero"
              className={`${
                !inView ? "opacity-100" : "opacity-0"
              } transition duration-300 z-10`}
            >
              <img
                className="h-7 w-7 rounded-full filter grayscale hover:grayscale-0 cursor-pointer "
                src="images/up.png"
                alt="home button"
              />
            </a>
          </div>
        </footer>

        <Toaster
          toastOptions={{
            className: "bg-red-500",
            style: {
              color: "#F7AB0A",
              background: "rgba(156,163,175,0.2)",
            },
          }}
        />
      </div>
    </div>
  );
}

export default App;

import React from "react";
import { EnvelopeIcon } from "@heroicons/react/24/solid";
import { useForm } from "react-hook-form";
import validator from "validator";
import Axios from "../axios";
import toast from "react-hot-toast";
import { motion } from "framer-motion";

function Contact({ pageInfo }) {
  const { register, handleSubmit } = useForm();

  const container = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
    },
  };

  const onSubmit = async (data) => {
    const toastId = toast.loading("Sending...");
    const subject = validator.escape(data.subject);
    const name = validator.escape(data.name);
    const email = validator.escape(data.email);
    const message = validator.escape(data.message);

    try {
      const response = await Axios.post("/mailTo", {
        subject,
        name,
        email,
        message,
      });
      if (response.data === "success") {
        toast.success("Message sent Succefully", {
          id: toastId,
        });
        return;
      }
    } catch (e) {
      toast.error("There is an error sending Message!", {
        id: toastId,
      });
    }
  };

  return (
    <motion.div
      variants={container}
      initial="initial"
      whileInView="animate"
      transition={{
        duration: 1.5,
      }}
      className="relative h-screen px-10 flex overflow-hidden flex-col text-center md:text-left md:flex-row max-w-7xl justify-center mx-auto items-center z-0"
    >
      <h3 className="absolute top-24 tracking-[20px] uppercase text-gray-500 text-2xl">
        Contact
      </h3>

      <div className="flex flex-col space-y-4 md:space-y-10 mt-7">
        <h4 className="text-4xl font-semibold text-center">Let's Connect</h4>

        <div className="space-y-10">
          <div className="flex items-center justify-center space-x-5 p-2">
            <EnvelopeIcon className="h-7 w-7 animate-pulse text-[#F7AB0A]" />
            <p className="text-2xl font-semibold text-center">
              {pageInfo ? pageInfo : "ajnasali777@gmail.com"}
            </p>
          </div>
        </div>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col items-center sm:items-stretch w-full space-y-2 sm:w-fit mx-auto mr-4 sm:mr-0"
        >
          <div className="flex flex-col sm:flex-row items-center justify-center  sm:space-x-2 space-y-2 sm:space-y-0 w-full">
            <input
              {...register("name")}
              placeholder="Name"
              className="contactInput w-full"
              type="text"
              required
            />
            <input
              {...register("email")}
              placeholder="Email"
              className="contactInput w-full"
              type="email"
              required
            />
          </div>

          <input
            {...register("subject")}
            placeholder="Subject"
            className="contactInput w-full"
            type="text"
          />
          <textarea
            {...register("message")}
            placeholder="Message"
            className="contactInput w-full"
          />
          <button
            type="submit"
            className=" bg-[#F7AB0A] py-5 px-10 rounded-md text-black font-bold text-lg w-full"
          >
            Submit
          </button>
        </form>
      </div>
    </motion.div>
  );
}

export default Contact;

import React from "react";

function LinkToBuild({ e }) {
  return (
    <div className="w-full  flex items-center justify-center">
      <div className=" flex items-center justify-center bg-gray-600/50 px-4 py-1 rounded-lg shadow-2xl space-x-2 cursor-pointer hover:text-[#F7AB0A]/80 duration-300 text-[#F7AB0A]/60">
        <a
          className="flex items-center justify-center   font-medium"
          href={e.linkToBuild}
        >
          Application URL
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            className="w-6 h-6 ml-2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
            />
          </svg>
        </a>
      </div>
    </div>
  );
}

export default LinkToBuild;

import React from 'react'
import Skill from './Skill'
import { motion } from 'framer-motion'

function Skills({ skillInfo }) {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1.5 }}
            className='min-h-screen relative flex flex-col text-center md:text-left xl:flex-row max-w-[2000px] xl:px-10 justify-center xl-space-y-0 mx-auto items-center'>
            <h3 className='absolute top-24 tracking-[20px] uppercase text-gray-500 text-2xl'>Skills</h3>

            <div className='grid grid-cols-3 md:grid-cols-5 gap-3 md:gap-5'>
                {
                    skillInfo?.map(e => {
                        return <Skill key={e._id} imgUrl={e.imageUrl} title={e.title} />
                    }) || <>Loading</>
                }


            </div>
        </motion.div>
    )
}

export default Skills